import React from "react";

import AccordionItem from "./AccordionItem";

import "./Accordion.styles.scss";

export type AccordItem = {
	title: string;
	description: string;
};

type AccordionProps = {
	items: AccordItem[];
};

const Accordion = ({ items }: AccordionProps) => {
	const [currentIndex, setCurrentIndex] = React.useState<number>(0);

	const toggleAccordion = (index: number) => () => {
		setCurrentIndex(currentIndex === index ? -1 : index);
	};

	return (
		<div className="accordion-wrapper">
			{items.map((item: AccordItem, index: number) => {
				const { title, description } = item;
				const key = index + 1 + "_" + title;

				return (
					<AccordionItem
						key={key}
						description={description}
						isOpen={currentIndex === index}
						onToggle={toggleAccordion(index)}
						title={title}
					/>
				);
			})}
		</div>
	);
};
export default Accordion;
