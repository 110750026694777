import React from "react";

import { ChooseUs, FaqSection, GetInTouch, Services, WorkStreamline } from "../containers";

import HomeBanner from "./components";

import "./HomePage.scss";

const HomePage = () => {
	return (
		<div className="home-page">
			<HomeBanner />

			<ChooseUs />

			<GetInTouch />

			<Services />

			<WorkStreamline />

			<FaqSection />
		</div>
	);
};

export default HomePage;
