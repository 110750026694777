import { Banner } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ContactUsForm } from "../containers";

const ContactUs = () => {
	const { t } = useTranslation(["contact"]);

	return (
		<div>
			<Banner title={t("contact:bannerTitle")} />

			<ContactUsForm />
		</div>
	);
};

export default ContactUs;
