import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import "./AboutStreamline.styles.scss";

type IAboutStreamlineProps = WithTranslation;

class AboutStreamline extends React.PureComponent<IAboutStreamlineProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="about-stream-container">
				<div className="about-streamline">
					<div className="about-left-panel">
						<p className="panel-tagline">{t("modules:streamline.tagline")}</p>

						<h3 className="panel-title">{t("modules:streamline.pageHeading")}</h3>

						<div className="p-container">
							<p>{t("modules:streamline.pageSection1")}</p>
							<p>{t("modules:streamline.pageSection2")}</p>
						</div>
					</div>

					<div className="about-right-panel">
						<img alt="streamline" src="/assets/images/about-stream.svg" />
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(["modules"])(AboutStreamline);
