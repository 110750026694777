import { CommonApiResult, httpService } from "../../data-access";
import { ContactFormModel } from "../models/PageModel";

export const subscribeUser = async (email: string): Promise<void> => {
	try {
		await httpService.post<CommonApiResult>("subscribe", {
			email
		});
	} catch (error) {
		console.error(error);
		throw new Error("error occurred while subscribing ");
	}
};

export const sendContactMessage = async (formValues: ContactFormModel): Promise<void> => {
	try {
		await httpService.post<CommonApiResult>("get-in-touch", formValues);
	} catch (error) {
		console.error(error);
		throw new Error("error occurred while sending message ");
	}
};
