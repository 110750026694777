import ContactUs from "pages/contact-us";
import React from "react";

import BasicLayout from "../layouts/BasicLayout";

const ContactUsRoute = () => {
	return (
		<BasicLayout>
			<ContactUs />
		</BasicLayout>
	);
};

export default ContactUsRoute;
