import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import PageModel from "../../models/PageModel";

import VariantBox from "./VariantBox";

import "./ChooseUsVariant.styles.scss";

type IChooseUsVariant = WithTranslation;

class ChooseUsVariant extends React.PureComponent<IChooseUsVariant> {
	public render() {
		return (
			<div className="variant-content-wrapper">
				<div className="variant-left">
					{this.getVariantContents().map((item: PageModel, index: number) => (
						<VariantBox key={item.title} index={index + 1} item={item} />
					))}
				</div>
				<div className="variant-right">
					<img alt="efficiency" src="/assets/images/choose-efficiency.svg" />
				</div>
			</div>
		);
	}

	private getVariantContents = (): PageModel[] => {
		const { t } = this.props;

		return [...Array(3)].map((_: any, index: number) => {
			const path = "modules:chooseUsVariant.option" + (index + 1);

			return {
				title: t(`${path}.title`),
				description: t(`${path}.description`)
			};
		});
	};
}

export default withTranslation(["modules"])(ChooseUsVariant);
