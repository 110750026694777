import React from "react";

import { usePublic } from "../../../utilities";
import PageModel from "../../models/PageModel";

type ServiceItemProps = {
	item: PageModel;
};

const ServiceItem = ({ item }: ServiceItemProps) => {
	const { icon, description, title } = item;

	const iconPath = usePublic(`/assets/images/${icon}.svg`);

	return (
		<div className="service-item-wrapper">
			<div className="service-icon">
				<img alt="s" src={iconPath} />
			</div>
			<div className="service-content">
				<h3 className="service-title">{title}</h3>
				<p className="service-content-desc">{description}</p>
			</div>
		</div>
	);
};

export default ServiceItem;
