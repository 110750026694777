import { getString } from "env";

const prefix = "REACT_APP";

const getPrefix = (key: string) => `${prefix}_${key}`;

const version = getString("VERSION", "0.0.0-development");

const globalConfig = {
	version,
	// internationalization
	publicPath: getString(getPrefix("PUBLIC_PATH"), "/"),
	rootUrl: getString(getPrefix("BASE_URL"), "/"),
	i18n: {
		locales: ["en"],
		defaultLocale: "en"
	}
};

export default globalConfig;
