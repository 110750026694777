import React, { EventHandler, MouseEventHandler } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Paths } from "../../MainRouter";

import "./Footer.styles.scss";

type IFooterProps = WithTranslation;

class Footer extends React.PureComponent<IFooterProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="footer-outer-wrapper">
				<div className="footer-wrapper">
					<div className="footer-content">
						{/* First Column */}
						<div className="footer-column">
							<img alt="eurycele" className="footer-brand-logo" src="/assets/images/ec_white.svg" />
							<p>{t("common:footers.shortDescription")}</p>
						</div>

						{/* Second Column */}
						<div className="footer-column">
							<h3 className="header">{t("common:footers.navigate")}</h3>
							<ul>
								<li>
									<Link className="link" onClick={this.onLinkClick} to={Paths.aboutUs}>
										{t("common:menus.aboutUs")}
									</Link>
								</li>
								<li>
									<Link className="link" onClick={this.onLinkClick} to={Paths.ourWork}>
										{t("common:menus.services")}
									</Link>
								</li>
							</ul>
						</div>

						{/* Third Column */}
						<div className="footer-column center-aligned">
							<h3 className="header">{t("common:contactUs")}</h3>

							<a
								className="link"
								href="https://maps.app.goo.gl/m5aVbmAFN2nrUFdR7"
								onClick={this.onLinkClick}
								rel="noreferrer"
								target="_blank">
								{t("common:footers.addressLine1")}
							</a>

							<a className="link" href="mailto:someone@example.com" onClick={this.onLinkClick}>
								{t("common:footers.addressLine2")}
							</a>
						</div>

						{/* Fourth Column */}
						<div className="footer-column">
							<Link className="btn-get-in-touch" to={Paths.contactUs} type="button">
								{t("common:menus.getInTouch")}
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.currentTarget.blur(); // Remove focus from the clicked link
	};
}

const translation = withTranslation(["common"]);

export default translation(Footer);
