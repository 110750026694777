import React, { useEffect } from "react";

import { useHeaderMenuContext } from "./HeaderMenuContext";
import MenuList from "./MenuList";

type MobileMenuProps = {
	setMenuOpen: (isOpen: boolean) => void;
};

const MobileMenu = ({ setMenuOpen }: MobileMenuProps) => {
	const { isMenuOpen, toggleMenu } = useHeaderMenuContext();
	const iconName = isMenuOpen ? "fa-xmark" : "fa-bars";

	useEffect(() => {
		setMenuOpen(isMenuOpen);
	}, [isMenuOpen, setMenuOpen]);

	return (
		<>
			<button aria-label="menu" className="icon-button" onClick={toggleMenu} type="button">
				<i className={`fa-solid ${iconName} mobile-menu-icon`} />
			</button>

			{isMenuOpen && (
				<div className="mobile-menu-list">
					<MenuList parentClassName="dialog-mobile-menu" />
				</div>
			)}
		</>
	);
};

export default MobileMenu;
