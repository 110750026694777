import { Accordion, AccordItem } from "components";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import "./FaqSection.styles.scss";

type IFaqSectionProps = WithTranslation;

class FaqSection extends React.PureComponent<IFaqSectionProps> {
	public render() {
		const { t, tReady } = this.props;

		if (!tReady) {
			return null;
		}

		const queries: AccordItem[] = t("faqs:queries", { returnObjects: true });

		return (
			<div className="faq-outer-wrapper">
				<div className="faq-wrapper">
					<div className="faq-heading">
						<p className="faq-tagline">{t("faqs:tagline")}</p>

						<h3 className="faq-title">{t("faqs:heading")}</h3>
						<p className="faq-description">{t("faqs:subHeading")}</p>
					</div>

					<div className="faq-panel-wrapper">
						<div className="faq-left-panel">
							<img alt="faq" src="/assets/images/faq-illus.svg" />
						</div>
						<div className="faq-right-panel">
							<Accordion items={queries} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const translation = withTranslation(["faqs"]);

export default translation(FaqSection);
