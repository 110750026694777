import NotFoundPage from "pages/not-found-page";
import React from "react";

import BasicLayout from "../layouts/BasicLayout";

const NotFoundRoute = () => (
	<BasicLayout>
		<NotFoundPage />
	</BasicLayout>
);

export default NotFoundRoute;
