import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

export type HeaderMenuConfig = {
	isMenuOpen: boolean;
	toggleMenu: () => void;
	closeMenu: () => void;
};

// @ts-ignore
export const HeaderMenuContext = createContext<HeaderMenuConfig>(null);

export const useHeaderMenuContext = () => useContext(HeaderMenuContext);

export const HeaderMenuProvider = ({ children }: React.PropsWithChildren) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		document.body.style.overflow = isMenuOpen ? "hidden" : "unset";
	}, [isMenuOpen]);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prevIsMenuOpen: boolean) => !prevIsMenuOpen);
	}, []);

	const closeMenu = useCallback(() => {
		setIsMenuOpen(false);
	}, []);

	const contextValue = useMemo(() => ({ isMenuOpen, toggleMenu, closeMenu }), [closeMenu, isMenuOpen, toggleMenu]);

	return <HeaderMenuContext.Provider value={contextValue}>{children}</HeaderMenuContext.Provider>;
};
