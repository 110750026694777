import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm from "./ContactForm";
import "./ContactForm.styles.scss";

const ContactUsForm = () => {
	const { t } = useTranslation(["contact"]);

	return (
		<div className="contact-outer-wrapper">
			<div className="contact-wrapper">
				<div className="contact-heading">
					<p className="contact-tagline">{t("contact:contactUs")}</p>

					<h3 className="contact-title">{t("contact:heading")}</h3>
					<p className="contact-description">{t("contact:subHeading")}</p>
				</div>

				<ContactForm />
			</div>
		</div>
	);
};

export default ContactUsForm;
