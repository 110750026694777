import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import "./Services.styles.scss";
import PageModel from "../../models/PageModel";

import ServiceItem from "./ServiceItem";

const serviceIcons = ["mobile", "web", "software", "cp-app", "consultation", "announcement"];

type IServicesProps = WithTranslation;

class Services extends React.PureComponent<IServicesProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="services-out-wrapper">
				<div className="services-wrapper">
					<div className="services-content-box">
						<div className="services-heading">
							<p className="services-tagline">{t("modules:services.sectionName")}</p>

							<h3 className="services-title">{t("modules:services.title")}</h3>
							<p className="services-description">{t("modules:services.subTitle")}</p>
						</div>

						<div className="services-option">
							{this.getServiceList().map((service: PageModel) => (
								<ServiceItem key={service.icon} item={service} />
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	public getServiceList = (): PageModel[] => {
		const { t } = this.props;

		return [...Array(6)].map((_: any, index: number) => {
			const path = "modules:services.option" + (index + 1);

			return {
				icon: serviceIcons[index],
				title: t(`${path}.title`),
				description: t(`${path}.description`)
			};
		});
	};
}

const translation = withTranslation(["modules"]);

export default translation(Services);
