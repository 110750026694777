import React from "react";

import "./WorkStreamline.styles.scss";
import { WithTranslation, withTranslation } from "react-i18next";

type IWorkStreamlineProps = WithTranslation;

class WorkStreamline extends React.PureComponent<IWorkStreamlineProps> {
	public render() {
		const { t, tReady } = this.props;

		if (!tReady) {
			return null;
		}

		const industries: string[] = t("modules:streamline.industries", { returnObjects: true });

		return (
			<div className="work-streamline">
				<div className="streamline-content-wrapper">
					<div className="streamline-left-panel">
						<img alt="efficiency" src="/assets/images/streamline.svg" />
					</div>
					<div className="streamline-right-panel">
						<div className="streamline-tagline">{t("modules:streamline.tagline")}</div>
						<h3 className="streamline-title">{t("modules:streamline.heading")}</h3>

						<p className="streamline-desc">{t("modules:streamline.description")}</p>

						<div className="streamline-services">
							{industries.map((service: string) => (
								<div key={service} className="service-item">
									<img alt="@" src="/assets/images/checkmark.svg" />
									<span>{service}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const translation = withTranslation(["modules"]);

export default translation(WorkStreamline);
