import ErrorPage from "pages/error-page";
import React from "react";

import BasicLayout from "../layouts/BasicLayout";

const InternalErrorRoute = () => (
	<BasicLayout>
		<ErrorPage />
	</BasicLayout>
);

export default InternalErrorRoute;
