import dayjs from "dayjs";
import timeZonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";
import i18n, { InitOptions } from "i18next";
import i18nextHTTPBackend from "i18next-http-backend";
import join from "url-join";

import { CreateClientReturn, InternalConfig } from "./i18n.types";

dayjs.extend(utcPlugin);
dayjs.extend(timeZonePlugin);

const createDefaultConfig = (timeZone: string): InitOptions => ({
	defaultNS: "common",
	ns: [],

	interpolation: {
		escapeValue: false,
		format: (value: string | Date, format?: string) => {
			if (value instanceof Date) {
				return dayjs(value).tz(timeZone).format(format) ?? value;
			}

			return value;
		},
		formatSeparator: ","
	},

	react: {
		useSuspense: false
	},

	returnNull: true,
	load: "currentOnly"
});

const createI18Instance = (publicPath: string, config: InternalConfig): CreateClientReturn => {
	const instance = i18n.createInstance();

	instance.use(i18nextHTTPBackend);

	const initPromise = instance.init({
		...createDefaultConfig(config.timeZone),
		lng: config.currentLocale || config.i18n.defaultLocale,
		supportedLngs: config.i18n.locales,

		backend: {
			loadPath: join(publicPath, "locales/{{lng}}/{{ns}}.json")
		}
	});

	return { i18n: instance, initPromise };
};

export default createI18Instance;
