import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Dialog, Spinner } from "../../../components";
import { isValidEmail, usePublic } from "../../../utilities";
import { subscribeUser } from "../../axios/api";

const SubscribeInput = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setHasError] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);

	const location = useLocation();
	const { t } = useTranslation(["common", "modules"]);

	const letterImg = usePublic("/assets/images/letter.svg");

	const onEmailChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(ev.target.value);
		setHasError("");
	}, []);

	const reset = useCallback(() => {
		setEmail("");
		setHasError("");
	}, []);

	// subscribe to user
	const subscribe = useCallback(() => {
		if (!email) {
			return;
		}

		if (isValidEmail(email)) {
			setLoading(true);
			subscribeUser(email)
				.then(() => {
					setDialogOpen(true);
				})
				.catch((err: Error) => {
					setHasError(t("common:errors.message"));
					console.error(err);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setHasError(t("common:errors.invalidEmail"));
		}
	}, [email, t]);

	const onDialogClose = useCallback(() => {
		setDialogOpen(false);
		reset();
	}, [reset]);

	// reset Subscription on location change
	useEffect(() => {
		reset();
	}, [location, reset]);

	return (
		<>
			<div className="subscribe-input-wrapper">
				<div className="input-wrapper">
					<div className="form-input-field">
						<img alt="x" src={letterImg} />
						<input
							className="form-input"
							onChange={onEmailChange}
							placeholder={t("modules:subscribe.inputPlaceholder")}
							type="email"
							value={email}
						/>
					</div>

					<div className="btn-form-submit">
						<button className="submit-btn" disabled={loading} onClick={subscribe} type="button">
							{loading ? <Spinner /> : t("modules:subscribe.buttonSubmit")}
						</button>
					</div>
				</div>

				{error && (
					<div className="input-error">
						<div className="error-message">{error}</div>
					</div>
				)}
			</div>

			<Dialog
				description={t("common:success.messageTagline")}
				message={t("common:success.message")}
				onClose={onDialogClose}
				open={dialogOpen}
			/>
		</>
	);
};

export default SubscribeInput;
