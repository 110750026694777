import React, { createContext, useContext } from "react";

export type RuntimeConfig = {
	version: string;
	publicPath: string;
	rootUrl: string;
	i18n: {
		locales: string[];
		defaultLocale: string;
	};
};

// @ts-ignore
export const RuntimeContext = createContext<RuntimeConfig>(null);

export const useRuntimeConfig = () => useContext(RuntimeContext);

export type RuntimeProviderProps = {
	runtime: RuntimeConfig;
};

export const RuntimeProvider = ({ runtime, children }: React.PropsWithChildren<RuntimeProviderProps>) => (
	<RuntimeContext.Provider value={runtime}>{children}</RuntimeContext.Provider>
);
