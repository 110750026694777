import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { AccordItem } from ".";

type AccordionItemProps = AccordItem & {
	isOpen: boolean;
	onToggle: () => void;
};

const AccordionItem = ({ isOpen, title, description, onToggle }: AccordionItemProps) => {
	return (
		<div className="accordion-item">
			<motion.header className="accordion-item-header" initial={false} onClick={onToggle}>
				<span>{title}</span>

				<motion.span
					animate={{ rotate: isOpen ? 0 : 180 }}
					initial={{ rotate: 180 }}
					transition={{ duration: 0.5 }}>
					<i className="fa-solid fa-caret-up" />
				</motion.span>
			</motion.header>

			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.section
						key="content"
						animate="open"
						exit="collapsed"
						initial="collapsed"
						transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
						variants={{
							open: { opacity: 1, height: "auto" },
							collapsed: { opacity: 0, height: 0 }
						}}>
						<motion.div
							className="accord-content-placeholder"
							transition={{ duration: 0.8 }}
							variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}>
							{description}
						</motion.div>
					</motion.section>
				)}
			</AnimatePresence>
		</div>
	);
};

export default AccordionItem;
