export const toBoolean = (value: string) => value === "true" || value === "1" || value === "on" || false;

export const getValue = <T>(key: string, fallbackValue: T, format: (value: string) => T): T => {
	const value = process.env[key];

	return value === undefined || value === "" ? fallbackValue : format(value);
};

export const getString = (key: string, fallbackValue: string): string =>
	getValue(key, fallbackValue, (value: string) => value.toString());

export const getBoolean = (key: string, fallbackValue: boolean): boolean => getValue(key, fallbackValue, toBoolean);
