import React from "react";

import "./Vision.styles.scss";
import { useTranslation } from "react-i18next";

const OurVision = () => {
	const { t } = useTranslation(["modules"]);

	return (
		<div className="vision-outer-wrapper">
			<div className="vision-state">
				<div className="vision-left-panel">
					<h3 className="panel-title">{t("modules:vision.title")}</h3>

					<div className="p-container">
						<p>{t("modules:vision.description1")}</p>
						<p>{t("modules:vision.description2")}</p>
					</div>
				</div>

				<div className="vision-right-panel">
					<img alt="vision" src="/assets/images/vision.svg" />
				</div>
			</div>
		</div>
	);
};

export default OurVision;
