const CustomBreakPoints = {
	Mobile: {
		MaxWidth: 767
	},
	Tablet: {
		MinWidth: 768,
		MaxWidth: 991
	},
	Desktop: {
		MinWidth: 992
	},
	Default: {
		MinWidth: 768
	}
};

export default CustomBreakPoints;
