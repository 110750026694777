import { createI18Instance, globalConfig, RuntimeProvider } from "config";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

const { i18n } = createI18Instance(globalConfig.publicPath, {
	currentLocale: "en",
	timeZone: "Asia/Calcutta",
	i18n: {
		defaultLocale: "en",
		locales: ["en"]
	}
});

const app = (
	<BrowserRouter>
		<I18nextProvider i18n={i18n}>
			<RuntimeProvider runtime={globalConfig}>
				<App />
			</RuntimeProvider>
		</I18nextProvider>
	</BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
