import React from "react";
import { useTranslation } from "react-i18next";

import SubscribeInput from "./SubscribeInput";
import "./GetInTouch.styles.scss";

const GetInTouch = () => {
	const { t } = useTranslation(["modules"]);

	return (
		<div className="get-wrapper-outside">
			<div className="get-wrapper">
				<div className="get-left-panel">
					<p className="desc-info">{t("modules:subscribe.tagline")}</p>
					<h3 className="get-title">{t("modules:subscribe.title")}</h3>

					<SubscribeInput />
				</div>
				<div className="get-right-panel">
					<img alt="connect" src="/assets/images/customer-connect.svg" />
				</div>
			</div>
		</div>
	);
};

export default GetInTouch;
