import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PageModel from "../../models/PageModel";
import ChooseUsVariant from "../choose-us-variant";

import ChooseUsTileContent from "./ChooseUsTileContent";
import "./ChooseUs.styles.scss";

const chooseUsImages = ["handshake", "settings", "target"];

const ChooseUs = () => {
	const { t } = useTranslation(["modules"]);

	const chooseUsContents: PageModel[] = useMemo(() => {
		return [...Array(3)].map((_: any, index: number) => {
			const path = "modules:chooseUs.option" + (index + 1);

			return {
				icon: chooseUsImages[index],
				title: t(`${path}.title`),
				description: t(`${path}.description`)
			};
		});
	}, [t]);

	return (
		<div className="choose-container">
			<div className="choose-wrapper">
				<div className="choose-heading">
					<p className="choose-tagline">{t("modules:chooseUs.sectionName")}</p>

					<h3 className="choose-title">{t("modules:chooseUs.title")}</h3>
					<p className="choose-description">{t("modules:chooseUs.subTitle")}</p>
				</div>

				<div className="choose-tiles">
					{chooseUsContents.map((content: PageModel) => (
						<ChooseUsTileContent key={content.icon} tileContent={content} />
					))}
				</div>

				<ChooseUsVariant />
			</div>
		</div>
	);
};

export default ChooseUs;
