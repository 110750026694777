import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./ScrollTop.style.scss";
import { useLocation } from "react-router-dom";

type ScrollTopProps = {
	scrolled: boolean;
};

const ScrollTop = ({ scrolled }: ScrollTopProps) => {
	const location = useLocation();
	const { t } = useTranslation(["common"]);

	const scrollToTop = useCallback(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}, []);

	useEffect(() => {
		// on change of location scroll to top
		scrollToTop();
	}, [location, scrollToTop]);

	return (
		<div className="scroll-top">
			{scrolled && (
				<button aria-label={t("scrollTop")} onClick={scrollToTop} type="button">
					<span>
						<i className="fa-solid fa-caret-up" />
					</span>
				</button>
			)}
		</div>
	);
};

export default ScrollTop;
