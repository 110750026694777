import { Paths } from "MainRouter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";

import { useHeaderMenuContext } from "./HeaderMenuContext";

export type MenuItem = {
	text: string;
	to: string;
};

type IMenuListProps = {
	parentClassName?: string;
};

const MenuList = ({ parentClassName }: IMenuListProps) => {
	const { closeMenu } = useHeaderMenuContext();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation(["common"]);

	const isActive = useCallback(
		(url: string) => location.pathname.toLowerCase() === url.toLowerCase(),
		[location.pathname]
	);

	const navMenus: MenuItem[] = useMemo(
		() => [
			{ text: t("common:menus.aboutUs"), to: Paths.aboutUs },
			{ text: t("common:menus.whatWeDo"), to: Paths.ourWork },
			{ text: t("common:menus.getInTouch"), to: Paths.contactUs }
		],
		[t]
	);

	const handleNavigation = useCallback(
		(menu: MenuItem) => () => {
			closeMenu();
			navigate(menu.to);
		},
		[closeMenu, navigate]
	);

	return (
		<ul className={parentClassName || ""}>
			{navMenus.map((menu: MenuItem) => (
				<li key={menu.to} className={isActive(menu.to) ? "active" : ""}>
					<Link onClick={handleNavigation(menu)} to={menu.to}>
						{menu.text}
					</Link>
				</li>
			))}
		</ul>
	);
};

export default MenuList;
