import { Banner } from "components";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ChooseUs, GetInTouch, Services } from "../containers";

type IOurWOrkProps = WithTranslation;

class OurWork extends React.PureComponent<IOurWOrkProps> {
	public render() {
		const { t } = this.props;

		return (
			<div>
				<Banner title={t("common:menus.whatWeDo")} />

				<Services />

				<GetInTouch />

				<ChooseUs />
			</div>
		);
	}
}

export default withTranslation(["common"])(OurWork);
