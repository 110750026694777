import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import "./Mission.styles.scss";

type IMissionStatementProps = WithTranslation;

class MissionStatement extends React.PureComponent<IMissionStatementProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="mission-outer-wrapper">
				<div className="mission-state">
					<div className="mission-left-panel">
						<img alt="mission" src="/assets/images/mission.svg" />
					</div>

					<div className="mission-right-panel">
						<h3 className="panel-title">{t("modules:mission.title")}</h3>

						<div className="p-container">
							<p>{t("modules:mission.description1")}</p>
							<p>{t("modules:mission.description2")}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(["modules"])(MissionStatement);
