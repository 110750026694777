import React, { useMemo } from "react";

import { usePublic } from "../../../utilities";
import PageModel from "../../models/PageModel";

type ChooseUsTileContentProps = {
	tileContent: PageModel;
};

const ChooseUsTileContent = ({ tileContent }: ChooseUsTileContentProps) => {
	const { icon, title, description } = tileContent;

	const handshakeUrl = usePublic("/assets/images/handshake.svg");
	const targetUrl = usePublic("/assets/images/target.svg");
	const settingsUrl = usePublic("/assets/images/settings-up.svg");

	const imageUrl = useMemo((): string => {
		switch (icon) {
			case "handshake":
				return handshakeUrl;

			case "target":
				return targetUrl;

			case "settings":
			default:
				return settingsUrl;
		}
	}, [handshakeUrl, icon, settingsUrl, targetUrl]);

	return (
		<div className="choose-tile-wrapper">
			<div className="choose-tile">
				<div className="tile-icon">
					<img alt="x" src={imageUrl} />
				</div>

				<h3 className="tile-title">{title}</h3>

				<p className="tile-description">{description}</p>
			</div>
		</div>
	);
};

export default ChooseUsTileContent;
