import { AnimatePresence, motion } from "framer-motion";
import get from "lodash/get";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, Spinner } from "../../../components";
import { isValidEmail } from "../../../utilities";
import { sendContactMessage } from "../../axios/api";
import { ContactFormValues, FormProps } from "../../models/PageModel";

const initialFormProps: FormProps = {
	value: "",
	touched: false,
	valid: false
};

const animationProps = {
	initial: { opacity: 0, y: 20 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 20 },
	transition: { duration: 0.5 }
};

const ContactForm = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { t } = useTranslation(["contact"]);

	const [formValues, setFormValues] = useState<ContactFormValues>({
		email: { ...initialFormProps },
		subject: { ...initialFormProps },
		name: { ...initialFormProps },
		message: { ...initialFormProps }
	});

	const { name, email, subject, message } = formValues;

	const resetForm = useCallback(() => {
		setFormValues({
			email: { ...initialFormProps },
			subject: { ...initialFormProps },
			name: { ...initialFormProps },
			message: { ...initialFormProps }
		});
		setErrors([]);
	}, []);

	const isValidFormField = useCallback(
		(field: keyof ContactFormValues) => {
			const currentValue = get(formValues, [field, "value"], "");

			if (field === "email" && !isValidEmail(currentValue)) {
				return false;
			}

			return !!currentValue.trim();
		},
		[formValues]
	);

	const onFormUpdate = useCallback(
		(field: keyof ContactFormValues) => (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
			setFormValues((prevState: ContactFormValues) => ({
				...prevState,
				[field]: {
					...prevState[field],
					value: event.target.value,
					valid: isValidFormField(field),
					touched: true
				}
			}));
		},
		[isValidFormField]
	);

	const onDialogClose = useCallback(() => {
		resetForm();
		setIsDialogOpen(false);
	}, [resetForm]);

	const onFormSubmit = useCallback(() => {
		const { name: formName, email: formEmail, message: formMessage, subject: formSubject } = formValues;

		const errorList: string[] = [];

		if (!formName.valid) {
			errorList.push(t("contact:formErrors.nameError"));
		}

		if (!formSubject.valid) {
			errorList.push(t("contact:formErrors.subjectError"));
		}

		if (!formEmail.valid) {
			errorList.push(t("contact:formErrors.emailError"));
		}

		if (!formMessage.valid) {
			errorList.push(t("contact:formErrors.messageError"));
		}

		if (errorList.length > 0) {
			setErrors(errorList);
		} else {
			setErrors([]);
			setIsSubmitting(true);

			const formValues = {
				email: formEmail.value,
				subject: formSubject.value,
				name: formName.value,
				message: formMessage.value
			};

			sendContactMessage(formValues)
				.then(() => {
					setIsDialogOpen(true);
				})
				.catch((err: Error) => {
					setErrors([t("contact:apiError")]);
					console.error(err);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
	}, [formValues, t]);

	return (
		<>
			<AnimatePresence>
				<div className="contact-form-panel">
					{/* Name and Email Form field */}
					<div className="form-field-container">
						<div className="form-group">
							{name.value.length > 0 && (
								<motion.span key="name-label" {...animationProps}>
									{t("contact:placeholders.name")}
								</motion.span>
							)}
							<input
								onChange={onFormUpdate("name")}
								placeholder={t("contact:placeholders.name")}
								type="text"
								value={name.value}
							/>
						</div>

						<div className="form-group">
							{email.value.length > 0 && (
								<motion.span key="email-label" {...animationProps}>
									{t("contact:placeholders.email")}
								</motion.span>
							)}
							<input
								onChange={onFormUpdate("email")}
								placeholder={t("contact:placeholders.email")}
								type="text"
								value={email.value}
							/>
						</div>
					</div>

					{/* Subject Form field */}
					<div className="form-field-container">
						<div className="form-group">
							{subject.value.length > 0 && (
								<motion.span key="subject-label" {...animationProps}>
									{t("contact:placeholders.subject")}
								</motion.span>
							)}
							<input
								onChange={onFormUpdate("subject")}
								placeholder={t("contact:placeholders.subject")}
								type="text"
								value={subject.value}
							/>
						</div>
					</div>

					{/* Message Form field */}
					<div className="form-field-container">
						<div className="form-group">
							{message.value.length > 0 && (
								<motion.span key="Message" {...animationProps}>
									{t("contact:placeholders.message")}
								</motion.span>
							)}
							<textarea
								onChange={onFormUpdate("message")}
								placeholder={t("contact:placeholders.message")}
								rows={5}
								value={message.value}
							/>
						</div>
					</div>

					{errors && errors.length > 0 && (
						<div className="error-wrapper">
							<ul className="error-message">
								{errors.map((error: string) => (
									<li key={error}>{error}</li>
								))}
							</ul>
						</div>
					)}

					<div className="form-submit-container">
						<button className="btn-form-submit" disabled={isSubmitting} onClick={onFormSubmit} type="submit">
							{isSubmitting ? <Spinner /> : t("contact:btnSubmit")}
						</button>
					</div>
				</div>
			</AnimatePresence>

			<Dialog onClose={onDialogClose} open={isDialogOpen} />
		</>
	);
};

export default ContactForm;
