import AboutUs from "pages/about-us";
import React from "react";

import BasicLayout from "../layouts/BasicLayout";

const AboutUsRoute = () => {
	return (
		<BasicLayout>
			<AboutUs />
		</BasicLayout>
	);
};

export default AboutUsRoute;
