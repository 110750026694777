import { Paths } from "MainRouter";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { usePublic } from "utilities";

const HeaderLogo = () => {
	const isSmall = useMediaQuery({ maxWidth: "400px" });
	const imagePath = isSmall ? "assets/images/ec_white_logo.svg" : "assets/images/ec_white.svg";

	const headerImg = usePublic(imagePath);

	return (
		<Link className="logo" to={Paths.root}>
			<img alt="Eurycele" src={headerImg} />
		</Link>
	);
};

export default HeaderLogo;
