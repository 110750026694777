import { Paths } from "MainRouter";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./Banner.styles.scss";

type IBannerProps = {
	title: string;
} & WithTranslation;

class Banner extends React.PureComponent<IBannerProps> {
	public render() {
		const { title, t } = this.props;

		return (
			<div className="page-banner">
				<div className="banner-content">
					<h1 className="page-header">{title}</h1>
					<div className="page-path-wrapper">
						<Link to={Paths.root}>{t("common:home")}</Link>
						<span>/</span>
						<span className="page-path">{title}</span>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(["common"])(Banner);
