import { Footer, Header, ScrollTop } from "components";
import React, { useCallback, useEffect } from "react";

import "./BasicLayout.styles.scss";

export type NavLink = {
	text: string;
	to: string;
};

type BasicLayoutProps = object;

const BasicLayout = ({ children }: React.PropsWithChildren<BasicLayoutProps>) => {
	const [menuOpen, setMenuOpen] = React.useState(false);

	const [scrolled, setScrolled] = React.useState(false);

	const scrollEventListener = useCallback(() => {
		setScrolled(window.scrollY > 60);
	}, []);

	useEffect(() => {
		// eslint-disable-next-line ban/ban
		window.addEventListener("scroll", scrollEventListener);

		return () => {
			window.removeEventListener("scroll", scrollEventListener);
		};
	}, [scrollEventListener]);

	return (
		<div className="page">
			<Header scrolled={scrolled} setMenuOpen={setMenuOpen} />

			<div className={scrolled ? "children-panel" : ""}>{children}</div>

			<Footer />

			{!menuOpen && <ScrollTop scrolled={scrolled} />}
		</div>
	);
};

export default BasicLayout;
