import BasicLayout from "layouts/BasicLayout";
import HomePage from "pages/home-page";
import React from "react";

// import BasicLayout from "../layouts/basic-layout";

const HomeRoute = () => {
	return (
		<BasicLayout>
			<HomePage />
		</BasicLayout>
	);
};

export default HomeRoute;
