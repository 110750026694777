import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUsRoute from "routes/AboutUsRoute";
import ContactUsRoute from "routes/ContactUsRoute";
import HomeRoute from "routes/HomeRoute";
import InternalErrorRoute from "routes/InternalErrorRoute";
import NotFoundRoute from "routes/NotFoundRoute";
import OurWOrkRoute from "routes/OurWorkRoute";

import { withErrorBoundary } from "./layouts/RoutedErrorBoundary";

interface IRouteProps {
	path: string;
	element: any;
}

export enum Paths {
	aboutUs = "/about-us",
	contactUs = "/contact-us",
	error = "/error",
	notFound = "/not-found",
	root = "/",
	ourWork = "/what-we-do"
}

const routes: IRouteProps[] = [
	{
		path: Paths.root,
		element: <HomeRoute />
	},
	{
		path: Paths.aboutUs,
		element: <AboutUsRoute />
	},
	{
		path: Paths.ourWork,
		element: <OurWOrkRoute />
	},
	{
		path: Paths.contactUs,
		element: <ContactUsRoute />
	},
	{
		path: Paths.notFound,
		element: <NotFoundRoute />
	},
	{
		path: Paths.error,
		element: <InternalErrorRoute />
	}
];

const MainRouter = () => {
	return (
		<Routes>
			{routes.map((props: IRouteProps) => {
				const { path, element } = props;

				return <Route key={path} element={element} path={path} />;
			})}
		</Routes>
	);
};

export default withErrorBoundary(MainRouter);
