import React, { useEffect } from "react";

import "./Dialog.styles.scss";
import { useTranslation } from "react-i18next";

type IDialogProps = {
	open: boolean;
	onClose: () => void;
	message?: string;
	description?: string;
};

const Dialog = ({ open, onClose, message, description }: IDialogProps) => {
	const { t } = useTranslation(["common"]);

	useEffect(() => {
		document.body.style.overflow = open ? "hidden" : "unset";
	}, [open]);

	return (
		<div>
			{open && (
				<div>
					<div className="backdrop">
						<div className="dialog-container">
							<h3>{message || t("common:success.message2")}</h3>

							<p>{description || t("common:success.messageInfo")}</p>

							<button className="btn-done" onClick={onClose} type="button">
								{t("common:done")}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dialog;
