import React from "react";

import pageModel from "../../models/PageModel";

type VariantBoxProps = {
	item: pageModel;
	index: number;
};

const VariantBox = ({ item, index }: VariantBoxProps) => {
	const { title, description } = item;

	return (
		<div className="variant-box-wrapper">
			<div className="variant-box">
				<div className="index-indicator">
					<span>{index}</span>
				</div>

				<div className="variant-box-content">
					<div className="variant-box-heading">{title}</div>
					<div className="variant-box-desc">{description}</div>
				</div>
			</div>
		</div>
	);
};

export default VariantBox;
