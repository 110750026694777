import OurWork from "pages/our-work";
import React from "react";

import BasicLayout from "../layouts/BasicLayout";

const OurWOrkRoute = () => {
	return (
		<BasicLayout>
			<OurWork />
		</BasicLayout>
	);
};

export default OurWOrkRoute;
