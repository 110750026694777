import { Banner } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

import { AboutStreamline, GetInTouch, MissionStatement, OurVision } from "../containers";

const AboutUs = () => {
	const { t } = useTranslation(["common"]);

	return (
		<div>
			<Banner title={t("common:menus.aboutUs")} />

			<AboutStreamline />

			<GetInTouch />

			<MissionStatement />

			<OurVision />
		</div>
	);
};

export default AboutUs;
