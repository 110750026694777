import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	CancelTokenSource,
	InternalAxiosRequestConfig
} from "axios";
import { globalConfig } from "config";

import { ICancelablePromise, IEnhancedConfig } from "./models";

const baseUrl = globalConfig.rootUrl;

class HttpService {
	private client: AxiosInstance;
	private cancelationTokenMap: Map<string, CancelTokenSource>;

	constructor() {
		const config: AxiosRequestConfig = {
			timeout: 60000
		};

		this.client = axios.create(config);
		this.cancelationTokenMap = new Map<string, CancelTokenSource>();

		this.client.interceptors.request.use(
			(request: InternalAxiosRequestConfig) => {
				// injecting staging url for development
				request.baseURL = baseUrl;

				return request;
			},
			(error: Error) => Promise.reject(error)
		);

		this.client.interceptors.response.use(
			// tslint:disable-next-line: no-any
			(response: AxiosResponse<any>) => response,
			(error: AxiosError) => {
				if (!!error.response && error.response.status === 440) {
					const redirectKey = "location";
					const pageToLoad = error.response.headers[redirectKey];
					if (pageToLoad !== null) {
						// redirect to location field.
						window.location.href = pageToLoad;
					} else {
						window.location.reload();
					}
				}

				return Promise.reject(error);
			}
		);
	}

	public get<T>(url: string, config?: AxiosRequestConfig): ICancelablePromise<T> {
		const { axiosRequestConfig, cancelTokenId } = this._enhanceRequestConfig(config);

		const axiosPromise: Promise<T> = this.client
			.get(url, axiosRequestConfig)
			.then((response: AxiosResponse<T>) => this._onSuccessWithCancelToken(cancelTokenId, response))
			.catch((reason: Error) => this._onReject(url, reason, cancelTokenId));

		return this._extendPromiseToBeCancelable(axiosPromise, cancelTokenId);
	}

	// Return the raw AxiosResponse it self, caller will need to prcess the data, header, request by each indivisual
	// business logic
	public getRaw<T>(url: string, config?: AxiosRequestConfig): ICancelablePromise<AxiosResponse<T>> {
		const { axiosRequestConfig, cancelTokenId } = this._enhanceRequestConfig(config);

		const axiosPromise: Promise<AxiosResponse<T>> = this.client
			.get(url, axiosRequestConfig)
			.then((response: AxiosResponse<T>) => this._onSuccessRawWithCancelToken(cancelTokenId, response))
			.catch((reason: Error) => this._onReject(url, reason, cancelTokenId));

		return this._extendPromiseToBeCancelable(axiosPromise, cancelTokenId);
	}

	public post<T>(
		url: string,
		data: Record<string, unknown>,
		customHeader?: Record<string, unknown>,
		config?: AxiosRequestConfig,
		apiCancelTokenId?: string
	): ICancelablePromise<T> {
		const { axiosRequestConfig, cancelTokenId } = this._enhanceRequestConfig(config, customHeader, apiCancelTokenId);

		const axiosPromise: Promise<T> = this.client
			.post(url, data, axiosRequestConfig)
			.then((response: AxiosResponse<T>) => this._onSuccessWithCancelToken(cancelTokenId, response))
			.catch((reason: Error) => this._onReject(url, reason, cancelTokenId));

		return this._extendPromiseToBeCancelable(axiosPromise, cancelTokenId);
	}

	// Return the raw AxiosResponse itself, caller will need to process the data, header, request by each indivisual
	// business logic
	public postRaw<T>(
		url: string,
		data: Record<string, unknown>,
		customHeader?: Record<string, unknown>,
		config?: AxiosRequestConfig
	): ICancelablePromise<AxiosResponse<T>> {
		const { axiosRequestConfig, cancelTokenId } = this._enhanceRequestConfig(config, customHeader);

		const axiosPromise: Promise<AxiosResponse<T>> = this.client
			.post(url, data, axiosRequestConfig)
			.then((response: AxiosResponse<T>) => this._onSuccessRawWithCancelToken(cancelTokenId, response))
			.catch((reason: Error) => this._onReject(url, reason, cancelTokenId));

		return this._extendPromiseToBeCancelable(axiosPromise, cancelTokenId);
	}

	public patch<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<T> {
		return this.client.patch(url, data, config).then(this._onSuccess).catch(this._onReject.bind(this, url));
	}

	public patchReturn<T, U>(url: string, data: T): Promise<U> {
		return this.client.patch(url, data).then(this._onSuccess).catch(this._onReject.bind(this, url));
	}

	public put<T>(url: string, data: Record<string, unknown>, config?: AxiosRequestConfig): Promise<T> {
		return this.client.put(url, data, config).then(this._onSuccess).catch(this._onReject.bind(this, url));
	}

	// Return the raw AxiosResponse it self, caller will need to prcess the data, header, request by each indivisual
	// business logic
	public putRaw<T>(
		url: string,
		data: Record<string, unknown>,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return this.client.put(url, data, config).then(this._onSuccessRaw).catch(this._onReject.bind(this, url));
	}

	public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return this.client.delete(url, config).then(this._onSuccess).catch(this._onReject.bind(this, url));
	}

	public deleteOverride<T>(url: string, data: Record<string, unknown>, config?: AxiosRequestConfig): Promise<T> {
		return this.post(url, data, { "X-HTTP-Method-Override": "Delete" }, config);
	}

	// Return the raw AxiosResponse it self, caller will need to prcess the data, header, request by each indivisual
	// business logic
	public deleteOverrideRaw<T>(
		url: string,
		data: Record<string, unknown>,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return this.postRaw(url, data, { "X-HTTP-Method-Override": "Delete" }, config);
	}

	public cancelRequest(cancelTokenId: string, message?: string): Promise<void> {
		return Promise.resolve(this._getTokenFromMapAndCancel(cancelTokenId, message));
	}

	public cancelAllRequests(cancelTokenIds: string[]): Promise<void[]> {
		return Promise.all(cancelTokenIds.map((cts: string) => this.cancelRequest(cts)));
	}

	private _onSuccessWithCancelToken<T>(cancelTokenId: string, response: AxiosResponse<T>): Promise<T> {
		this.cancelationTokenMap.delete(cancelTokenId);

		return Promise.resolve(this._onSuccess(response));
	}

	private _onSuccessRawWithCancelToken<T>(
		cancelTokenId: string,
		response: AxiosResponse<T>
	): Promise<AxiosResponse<T>> {
		this.cancelationTokenMap.delete(cancelTokenId);

		return Promise.resolve(response);
	}

	private _onSuccess<T>(response: AxiosResponse<T>): T {
		return response.data;
	}

	private _onSuccessRaw<T>(response: AxiosResponse<T>): AxiosResponse<T> {
		return response;
	}

	private _onReject(_url: string, reason: Error, cancelTokenId?: string): Promise<never> {
		// if cancelTokenId is defined and the reason for reject is not cancel, remove it now that the request is complete
		// When we request cancel it removes the token after cancelling the token from the map
		if (cancelTokenId && !axios.isCancel(reason)) {
			this.cancelationTokenMap.delete(cancelTokenId);
		}

		return Promise.reject(reason);
	}

	private _getTokenFromMapAndCancel(cancelTokenId: string, message?: string): void {
		if (this.cancelationTokenMap.has(cancelTokenId)) {
			(this.cancelationTokenMap.get(cancelTokenId) as CancelTokenSource).cancel(message);
			// Since we have requested for cancellation remove it from the map as well
			this.cancelationTokenMap.delete(cancelTokenId);
		}
	}

	private _extendPromiseToBeCancelable<T>(axiosPromise: Promise<T>, cancelTokenId: string): ICancelablePromise<T> {
		const cancelableAxiosPromise: ICancelablePromise<T> = axiosPromise as ICancelablePromise<T>;
		cancelableAxiosPromise.cancelTokenId = cancelTokenId;

		return cancelableAxiosPromise;
	}

	private _enhanceRequestConfig(
		config?: AxiosRequestConfig,
		customHeader?: any,
		cancelTokenId?: string
	): IEnhancedConfig {
		config = config || {};

		if (customHeader) {
			config.headers = customHeader;
		}

		let randomId: string | undefined;
		if (cancelTokenId) {
			randomId = cancelTokenId;
		} else {
			// generated randomId to track the specific cts for a given request/promise.
			randomId = this._generateRandomId();

			// ensure that the randomId is unique
			while (this.cancelationTokenMap.has(randomId!)) {
				randomId = this._generateRandomId();
			}
		}
		const cts: CancelTokenSource = this._getCancelTokenSource();

		// add a map to cancelTokenMap for randomId -> cts
		this.cancelationTokenMap.set(randomId!, cts);
		config.cancelToken = cts.token;

		return {
			axiosRequestConfig: config,
			cancelTokenId: randomId!
		};
	}

	private _getCancelTokenSource(): CancelTokenSource {
		return axios.CancelToken.source();
	}

	private _generateRandomId(): string {
		return Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
	}
}

export const httpService = new HttpService();
