import React from "react";
import { useMediaQuery } from "react-responsive";
import { CustomBreakPoints } from "utilities";

import HeaderLogo from "./HeaderLogo";
import { HeaderMenuProvider } from "./HeaderMenuContext";
import MenuList from "./MenuList";
import MobileMenu from "./MobileMenu";

import "./Header.styles.scss";

type HeaderProps = {
	scrolled: boolean;
	setMenuOpen: (isOpen: boolean) => void;
};

const Header = ({ scrolled, setMenuOpen }: HeaderProps) => {
	const isTabletOrSmall = useMediaQuery({ maxWidth: CustomBreakPoints.Tablet.MaxWidth });

	return (
		<HeaderMenuProvider>
			<div className={`main-header ${scrolled ? "scrolled-position" : ""}`}>
				<div className="header-container">
					<HeaderLogo />

					{isTabletOrSmall ? <MobileMenu setMenuOpen={setMenuOpen} /> : <MenuList parentClassName="header-info" />}
				</div>
			</div>
		</HeaderMenuProvider>
	);
};

export default Header;
