import ErrorPage from "pages/error-page";
import React, { Component, ComponentType, ReactNode } from "react";
import { useLocation } from "react-router-dom";

export type Props = {
	pathname: string | null;
	children: JSX.Element | ReactNode;
};

export type State = {
	hasError: boolean;
	pathname: string | null;
};

class RoutedErrorBoundary extends Component<Props, State> {
	// tslint:disable: no-any
	public static getDerivedStateFromError(error: any): { hasError: boolean } {
		return { hasError: true };
	}

	public static getDerivedStateFromProps(props: Props, state: State): State {
		if (props.pathname !== state.pathname) {
			return { hasError: false, pathname: props.pathname };
		}

		return state;
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, pathname: null };
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		if ("development" !== process.env.NODE_ENV) {
			// print the error
			// do not print on development to avoid printing twice in console
			console.error(error);
		}

		// and capture it
		// Sentry.captureException(error);
	}

	public render(): React.ReactNode {
		const { hasError } = this.state;

		if (hasError) {
			return <ErrorPage />;
		}

		const { children } = this.props;

		return children;
	}
}

export default RoutedErrorBoundary;

export const withErrorBoundary = <IProps extends object = object>(
	WrappedComponent: ComponentType<Props>
): ComponentType<IProps> => {
	const enhancedComponent = (props: any) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const location = useLocation();

		return (
			<RoutedErrorBoundary pathname={location.pathname}>
				<WrappedComponent {...props} />
			</RoutedErrorBoundary>
		);
	};

	enhancedComponent.displayName = `withErrorBoundary(${
		WrappedComponent.displayName || WrappedComponent.name || "Component"
	})`;

	return enhancedComponent;
};
