import { Paths } from "MainRouter";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./HomeBanner.styles.scss";

type HomeBannerProps = WithTranslation;

class HomeBanner extends React.PureComponent<HomeBannerProps> {
	public render() {
		const { t } = this.props;

		return (
			<div className="home-banner-container">
				<div className="home-banner">
					<div className="banner-left-panel">
						<h3>{t("home:tagline")}</h3>

						<h1>{t("home:heading")}</h1>

						<p>{t("home:description")}</p>

						<Link className="btn-contact-us" to={Paths.contactUs} type="button">
							{t("common:contactUs")}
						</Link>
					</div>

					<div className="banner-right-panel">
						<img alt="banner" src="/assets/images/banner.svg" />
					</div>
				</div>
			</div>
		);
	}
}

const translation = withTranslation(["common", "home"]);

export default translation(HomeBanner);
